import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, NgxsAfterBootstrap, Selector, State, StateContext } from "@ngxs/store";
import { append, compose, iif, patch, removeItem, updateItem } from "@ngxs/store/operators";
import { ApplicationState } from "@vp/data-access/application";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsApiService } from "@vp/data-access/tags";
import { Organization, Tag, TagType, User } from "@vp/models";
import { LoggerService } from "@vp/shared/logger-service";
import { deeperCopy, mergeOperationsById } from "@vp/shared/utilities";

import { delay, tap } from "rxjs/operators";
import { unselectTag, unselectTagType } from "../actions/tag-manager-activated.effects";
import {
  addExtraRecipients,
  addServiceCost,
  addServiceCostPricingPlan,
  addSpecialty,
  deleteCostOverride,
  deletePlan,
  deleteRecipient,
  deleteServiceCost,
  deleteSpecialty,
  editServiceCost,
  patchCostOverride,
  patchPricingPlanOverride
} from "../actions/tag-manager-tagData.effects";
import { selectTagTypesAndSort, selectTagsAndSort } from "../actions/tag-manager.selector.effects";
import { TagManagerStateModel } from "../models/tag-manager-state.model";
import * as TagManagerActions from "./tag-manager.actions";

export const tagManagerDefaultState = {
  selectedTagType: null,
  selectedTag: null,
  tagSearch: "",
  tagTypeSearch: "",
  attention: {
    tagTypeSearch: false,
    tagTypeSelector: false
  },
  uiState: {
    showPreview: false,
    editStatus: "untouched"
  },
  tagTypes: [],
  tags: [],
  selectedTagTypes: [],
  selectedTags: [],
  operations: []
};

export const tagManagerState = "tagManagerState";

@State<TagManagerStateModel>({
  name: tagManagerState,
  defaults: tagManagerDefaultState
})
@Injectable()
export class TagManagerState implements NgxsAfterBootstrap {
  constructor(
    private readonly loggerService: LoggerService,
    private readonly tagsApiService: TagsApiService
  ) {}

  @Selector()
  static attention(state: TagManagerStateModel) {
    return { ...state.attention };
  }

  @Selector()
  static operations(state: TagManagerStateModel) {
    return [...state.operations];
  }

  @Selector()
  static getUiState(state: TagManagerStateModel) {
    return (filter: string) => state.uiState[filter] ?? false;
  }

  // Selected Tags

  @Selector()
  public static getSelectedTag(state: TagManagerStateModel): Tag | null {
    return state.selectedTag;
  }

  @Selector([TagManagerState.getSelectedTag])
  public static selectedTag(tag: Tag): Tag | null {
    return tag ? deeperCopy(tag) : null;
  }

  @Selector()
  static getSelectedTags(state: TagManagerStateModel): Tag[] {
    return state.selectedTags;
  }

  @Selector([TagManagerState.getSelectedTags])
  public static selectedTags(tags: Tag[]): Tag[] {
    return deeperCopy(tags);
  }

  @Selector()
  public static selectableTags(state: TagManagerStateModel) {
    return selectTagsAndSort(state);
  }

  @Selector()
  public static tags(state: TagManagerStateModel) {
    return [...state.tags];
  }

  @Selector()
  public static tagTypeSearch(state: TagManagerStateModel) {
    return state.tagTypeSearch;
  }

  @Selector()
  public static tagSearch(state: TagManagerStateModel) {
    return state.tagSearch;
  }

  // Selected Tag Types

  @Selector([TagManagerState, ApplicationState.loggedInUser, OrganizationState.organization])
  public static tagTypes(
    state: TagManagerStateModel,
    loggedInUser: User | null,
    organization: Organization
  ) {
    const roleFriendlyId = organization?.roles.find(
      role => role.roleId === loggedInUser?.selectedRoleId
    )?.friendlyId;

    const roleTagTypeRestrictions = organization?.features.find(
      feature => feature.friendlyId === "tagManager"
    )?.configurationDictionaries?.roleTagTypeRestrictions;

    if (roleTagTypeRestrictions && roleFriendlyId) {
      return selectTagTypesAndSort(
        state,
        state.tagTypeSearch,
        state.tagTypes,
        roleFriendlyId,
        roleTagTypeRestrictions
      );
    }
    return [];
  }

  @Selector()
  public static getSelectedTagType(state: TagManagerStateModel): TagType | null {
    return state.selectedTagType;
  }

  @Selector([TagManagerState.getSelectedTagType])
  public static selectedTagType(tagType: TagType): TagType | null {
    return tagType ? deeperCopy(tagType) : null;
  }

  @Selector()
  public static getSelectedTagTypes(state: TagManagerStateModel) {
    return [...state.selectedTagTypes];
  }

  /** Memoized selector
   * @param tagTypes
   * @returns {TagType[]}
   */
  @Selector([TagManagerState.getSelectedTagTypes])
  public static selectedTagTypes(tagTypes: TagType[]): TagType[] {
    return tagTypes;
  }

  // Actions

  @Action(TagManagerActions.UpdateState)
  updateState(
    ctx: StateContext<TagManagerStateModel>,
    { tagManagerState }: TagManagerActions.UpdateState
  ) {
    const updatedState = updateState(tagManagerState);
    ctx.patchState(updatedState);
  }

  // Activated Actions

  @Action(TagManagerActions.SelectTagType)
  selectTagType(
    ctx: StateContext<TagManagerStateModel>,
    { tagTypeId }: TagManagerActions.SelectTagType
  ) {
    const tagTypes = ctx.getState().tagTypes;
    const selectedTagType = tagTypes.find(tagType => tagType.tagTypeId === tagTypeId);
    if (!selectedTagType) return;

    // add selected tag type to selected tags
    ctx.setState(
      patch<TagManagerStateModel>({
        selectedTagType: selectedTagType,
        selectedTagTypes: append<TagType>([selectedTagType])
      })
    );
  }

  @Action(TagManagerActions.SelectTag)
  selectTag(ctx: StateContext<TagManagerStateModel>, { tagId }: TagManagerActions.SelectTag) {
    const selectedTag = deeperCopy(ctx.getState().tags.find(tag => tag.tagId === tagId));
    const selectedTags = [...ctx.getState().selectedTags];

    if (!selectedTag) return;

    /**
     * If there is a tag in the selected tag list with the same tagType then we want to replace it.
     * So for example if I have room tag types loaded and I select a new room them it will replace
     * the old room in the selected tags.
     */
    const alreadySelectedTag = selectedTags.find(
      t => t.tagTypeFriendlyId === selectedTag.tagTypeFriendlyId
    );

    ctx.setState(
      patch<TagManagerStateModel>({
        selectedTag: selectedTag,
        selectedTags: compose<Tag[]>(
          removeItem<Tag>(tag => tag?.tagId === alreadySelectedTag?.tagId),
          append<Tag>([selectedTag])
        )
      })
    );
  }

  @Action(TagManagerActions.AddTag)
  addTag(ctx: StateContext<TagManagerStateModel>, action: TagManagerActions.AddTag) {
    const selectedTag = ctx.getState().selectedTag;
    const selectedTagType = ctx.getState().selectedTagType;
    if (!selectedTagType) return;

    const selectedTags = [...ctx.getState().selectedTags];
    const alreadySelectedTag = selectedTags.find(
      tag => tag.tagTypeFriendlyId === selectedTag?.tagTypeFriendlyId
    );

    let tagToAdd = {
      ...action.tag,
      tagTypeId: selectedTagType?.tagTypeId,
      tagTypeFriendlyId: selectedTagType?.friendlyId
    };

    if (selectedTags.length > 0) {
      tagToAdd.tagPath =
        selectedTag?.tagTypeId === tagToAdd.tagTypeId
          ? selectedTag.tagPath
          : selectedTags.map(t => t.tagId).join(".");
    }

    // Not sure how I feel about this. Because tagData is an expando object
    // in the C# model, it always serializes out of the form group as a {}
    // so this is just making sure its null when its created if the value
    // isnt provided.
    if (
      !Object.prototype.hasOwnProperty.call(tagToAdd, "tagData") ||
      Object.keys(tagToAdd?.tagData ?? {}).length === 0
    ) {
      tagToAdd = {
        ...tagToAdd,
        tagData: null
      };
    }

    return this.tagsApiService.addTag(tagToAdd).pipe(
      tap(tag => {
        ctx.setState(
          patch<TagManagerStateModel>({
            selectedTag: tag,
            selectedTags: iif(
              selectedTag?.tagTypeId === tag.tagTypeId,
              compose<Tag[]>(
                removeItem<Tag>(tag => tag?.tagId === alreadySelectedTag?.tagId),
                append<Tag>([tag])
              ),
              append<Tag>([tag])
            ),
            tags: append<Tag>([tag])
          })
        );
      })
    );
  }

  @Action(TagManagerActions.DeleteTag)
  deleteTag(ctx: StateContext<TagManagerStateModel>, action: TagManagerActions.DeleteTag) {
    if (action.tagId === ctx.getState().selectedTag?.tagId) {
      this.tagsApiService.deleteTag(action.tagId).subscribe({
        next: () => {
          ctx.setState(
            patch<TagManagerStateModel>({
              selectedTag: null,
              selectedTags: removeItem<Tag>(tag => tag?.tagId === action.tagId),
              tags: removeItem<Tag>(tag => tag?.tagId === action.tagId),
              operations: []
            })
          );
        },
        error: (error: HttpErrorResponse) => {
          this.loggerService.errorEvent(
            error,
            `${this.constructor.name}.${this.deleteTag.name}`,
            "An Error occured when attempting to delete a tag."
          );
        }
      });
    }
  }

  @Action(TagManagerActions.UpdateTag)
  editTag(ctx: StateContext<TagManagerStateModel>, action: TagManagerActions.UpdateTag) {
    if (action.tagId === ctx.getState().selectedTag?.tagId) {
      if (action.tag) {
        const currentTag = ctx.getState().selectedTag;
        if (currentTag) {
          const updatedTag: Tag = {
            ...currentTag,
            ...deeperCopy(action.tag)
          };

          ctx.setState(
            patch<TagManagerStateModel>({
              selectedTag: updatedTag
            })
          );
        }
      }
    }
  }

  @Action(TagManagerActions.CancelEditingTag)
  cancelEditingTag(ctx: StateContext<TagManagerStateModel>) {
    const selectedTag = ctx.getState().selectedTag;
    const originalTag = ctx.getState().tags.find(tag => tag.tagId === selectedTag?.tagId);
    if (originalTag) {
      ctx.setState(
        patch<TagManagerStateModel>({
          selectedTag: originalTag,
          operations: [],
          uiState: patch<Record<string, unknown>>({
            editStatus: "cancelled"
          })
        })
      );
    }
  }

  @Action(TagManagerActions.UnselectTagType)
  unselectTagType(
    ctx: StateContext<TagManagerStateModel>,
    { tagTypeId }: TagManagerActions.SelectTagType
  ) {
    const selectedTagTypes = ctx.getState().selectedTagTypes;
    const tagTypeToUnselect = selectedTagTypes.find(tagType => tagType.tagTypeId === tagTypeId);
    if (tagTypeToUnselect) {
      const partialState = unselectTagType(
        ctx.getState().selectedTags,
        selectedTagTypes,
        tagTypeToUnselect
      );

      if (!partialState) return;

      ctx.setState(patch<TagManagerStateModel>(partialState));
    } else {
      // remove selected tag type from selected tags
      ctx.setState(
        patch<TagManagerStateModel>({
          selectedTagTypes: removeItem<TagType>(tagType => tagType?.tagTypeId === tagTypeId)
        })
      );
    }
  }

  @Action(TagManagerActions.UnselectTag)
  unselectTag(ctx: StateContext<TagManagerStateModel>, { tagId }: TagManagerActions.UnselectTag) {
    const selectedTags = [...ctx.getState().selectedTags];
    const tagToUnselect = selectedTags.find(tag => tag.tagId === tagId);
    if (!tagToUnselect) return;
    if (tagToUnselect?.tagPath) {
      const partialState = unselectTag(ctx.getState(), selectedTags, tagToUnselect);
      if (!partialState) return;
      ctx.patchState(partialState);
    } else if (selectedTags.map(t => t.tagId).indexOf(tagToUnselect.tagId) === 0) {
      ctx.setState(
        patch<TagManagerStateModel>({
          selectedTagType: null,
          selectedTag: null,
          selectedTagTypes: [],
          selectedTags: []
        })
      );
    } else {
      ctx.setState(
        patch<TagManagerStateModel>({
          selectedTag: null,
          selectedTags: removeItem<Tag>(tag => tag?.tagId === tagId)
        })
      );
    }
  }

  /// Tag Data Actions

  @Action(TagManagerActions.AddServiceCostPricingPlan)
  addServiceCostPricingPlan(
    ctx: StateContext<TagManagerStateModel>,
    action: TagManagerActions.AddServiceCostPricingPlan
  ) {
    const currentTagData = ctx.getState().selectedTag?.tagData;
    if (!currentTagData) return;

    const tagData = addServiceCostPricingPlan(currentTagData, action.planName);
    if (!tagData) return;

    const currentTag = ctx.getState().selectedTag;
    if (currentTag) {
      const updatedTag: Tag = {
        ...currentTag,
        tagData: tagData
      };

      ctx.setState({
        ...ctx.getState(),
        selectedTag: updatedTag
      });
    }
  }

  @Action(TagManagerActions.DeletePlan)
  deletePlan(ctx: StateContext<TagManagerStateModel>, action: TagManagerActions.DeletePlan) {
    const currentTagData = ctx.getState().selectedTag?.tagData;
    if (!currentTagData) return;

    const tagData = deletePlan(currentTagData, action.planName);
    if (!tagData) return;

    const currentTag = ctx.getState().selectedTag;
    if (currentTag) {
      const updatedTag: Tag = {
        ...currentTag,
        tagData: tagData
      };

      ctx.setState({
        ...ctx.getState(),
        selectedTag: updatedTag
      });
    }
  }

  // TODO: Maybe use this to update everything?
  @Action(TagManagerActions.PatchPricingPlanOverride)
  patchPricingPlanOverride(
    ctx: StateContext<TagManagerStateModel>,
    action: TagManagerActions.PatchPricingPlanOverride
  ) {
    const currentTagData = ctx.getState().selectedTag?.tagData;
    if (!currentTagData) return;

    const tagData = patchPricingPlanOverride(
      currentTagData,
      action.planName,
      action.overridePartial
    );
    if (!tagData) return;

    const currentTag = ctx.getState().selectedTag;
    if (currentTag) {
      const updatedTag: Tag = {
        ...currentTag,
        tagData: tagData
      };

      ctx.setState({
        ...ctx.getState(),
        selectedTag: updatedTag
      });
    }
  }

  @Action(TagManagerActions.AddSpecialty)
  addSpecialty(ctx: StateContext<TagManagerStateModel>, action: TagManagerActions.AddSpecialty) {
    const currentTagData = ctx.getState().selectedTag?.tagData;
    if (!currentTagData) return;

    const tagData = addSpecialty(currentTagData, action.planName, action.specialtyTagId);
    if (!tagData) return;

    const currentTag = ctx.getState().selectedTag;
    if (currentTag) {
      const updatedTag: Tag = {
        ...currentTag,
        tagData: tagData
      };

      ctx.setState({
        ...ctx.getState(),
        selectedTag: updatedTag
      });
    }
  }

  @Action(TagManagerActions.AddRecipient)
  addRecipient(ctx: StateContext<TagManagerStateModel>, action: TagManagerActions.AddRecipient) {
    const currentTagData = ctx.getState().selectedTag?.tagData;
    if (!currentTagData) return;

    const tagData = addExtraRecipients(currentTagData, action.recipientAdded);
    if (!tagData) return;

    const currentTag = ctx.getState().selectedTag;
    if (currentTag) {
      const updatedTag: Tag = {
        ...currentTag,
        tagData: tagData
      };

      ctx.setState({
        ...ctx.getState(),
        selectedTag: updatedTag
      });
    }
  }

  @Action(TagManagerActions.DeleteSpecialty)
  deleteSpecialty(
    ctx: StateContext<TagManagerStateModel>,
    action: TagManagerActions.DeleteSpecialty
  ) {
    const currentTagData = ctx.getState().selectedTag?.tagData;
    if (!currentTagData) return;

    const tagData = deleteSpecialty(currentTagData, action.planName, action.specialtyTagId);
    if (!tagData) return;

    const currentTag = ctx.getState().selectedTag;
    if (currentTag) {
      const updatedTag: Tag = {
        ...currentTag,
        tagData: tagData
      };

      ctx.setState({
        ...ctx.getState(),
        selectedTag: updatedTag
      });
    }
  }

  @Action(TagManagerActions.DeleteRecipient)
  deleteRecipient(
    ctx: StateContext<TagManagerStateModel>,
    action: TagManagerActions.DeleteRecipient
  ) {
    const currentTagData = ctx.getState().selectedTag?.tagData;
    if (!currentTagData) return;

    const tagData = deleteRecipient(currentTagData, action.recipientRemoved);
    if (!tagData) return;

    const currentTag = ctx.getState().selectedTag;
    if (currentTag) {
      const updatedTag: Tag = {
        ...currentTag,
        tagData: tagData
      };

      ctx.setState({
        ...ctx.getState(),
        selectedTag: updatedTag
      });
    }
  }

  @Action(TagManagerActions.PatchCostOverride)
  patchCostOverride(
    ctx: StateContext<TagManagerStateModel>,
    action: TagManagerActions.PatchCostOverride
  ) {
    const currentTagData = ctx.getState().selectedTag?.tagData;
    if (!currentTagData) return;

    const tagData = patchCostOverride(currentTagData, action.override);
    if (!tagData) return;

    const currentTag = ctx.getState().selectedTag;
    if (currentTag) {
      const updatedTag: Tag = {
        ...currentTag,
        tagData: tagData
      };

      ctx.setState(
        patch<TagManagerStateModel>({
          selectedTag: updatedTag
        })
      );
    }
  }

  @Action(TagManagerActions.DeleteCostOverride)
  deleteCostOverride(
    ctx: StateContext<TagManagerStateModel>,
    action: TagManagerActions.DeleteCostOverride
  ) {
    const currentTagData = ctx.getState().selectedTag?.tagData;
    if (!currentTagData) return;

    const tagData = deleteCostOverride(currentTagData, action.caseTypeId);
    if (!tagData) return;

    const currentTag = ctx.getState().selectedTag;
    if (currentTag) {
      const updatedTag: Tag = {
        ...currentTag,
        tagData: tagData
      };

      ctx.setState({
        ...ctx.getState(),
        selectedTag: updatedTag
      });
    }
  }

  @Action(TagManagerActions.AddServiceCost)
  addServiceCost(
    ctx: StateContext<TagManagerStateModel>,
    action: TagManagerActions.AddServiceCost
  ) {
    const currentTagData = ctx.getState().selectedTag?.tagData;
    if (!currentTagData) return;

    const tagData = addServiceCost(currentTagData, action.planName, action.serviceFee);
    if (!tagData) return;

    const currentTag = ctx.getState().selectedTag;
    if (currentTag) {
      const updatedTag: Tag = {
        ...currentTag,
        tagData: tagData
      };

      ctx.setState({
        ...ctx.getState(),
        selectedTag: updatedTag
      });
    }
  }

  @Action(TagManagerActions.EditServiceCost)
  editServiceCost(
    ctx: StateContext<TagManagerStateModel>,
    action: TagManagerActions.EditServiceCost
  ) {
    const currentTagData = ctx.getState().selectedTag?.tagData;
    if (!currentTagData) return;

    const tagData = editServiceCost(currentTagData, action.planName, action.serviceFee);
    if (!tagData) return;

    const currentTag = ctx.getState().selectedTag;
    if (currentTag) {
      const updatedTag: Tag = {
        ...currentTag,
        tagData: tagData
      };

      ctx.setState({
        ...ctx.getState(),
        selectedTag: updatedTag
      });
    }
  }

  @Action(TagManagerActions.DeleteServiceCost)
  deleteServiceCost(
    ctx: StateContext<TagManagerStateModel>,
    action: TagManagerActions.DeleteServiceCost
  ) {
    const currentTagData = ctx.getState().selectedTag?.tagData;
    if (!currentTagData) return;

    const tagData = deleteServiceCost(
      currentTagData,
      action.planName,
      action.serviceCostFriendlyId
    );

    if (!tagData) return;

    const currentTag = ctx.getState().selectedTag;
    if (currentTag) {
      const updatedTag: Tag = {
        ...currentTag,
        tagData: tagData
      };

      ctx.setState({
        ...ctx.getState(),
        selectedTag: updatedTag
      });
    }
  }

  @Action(TagManagerActions.MergeOperations)
  mergeOperations(
    ctx: StateContext<TagManagerStateModel>,
    { operations }: TagManagerActions.MergeOperations
  ) {
    const existingOperations = ctx.getState().operations;
    ctx.patchState({ operations: mergeOperationsById(existingOperations, operations) });
  }

  @Action(TagManagerActions.ResetOperations)
  resetOperations(ctx: StateContext<TagManagerStateModel>) {
    ctx.setState(
      patch<TagManagerStateModel>({
        operations: [],
        uiState: patch<Record<string, unknown>>({
          editStatus: "untouched"
        })
      })
    );
  }

  @Action(TagManagerActions.CommitOperations)
  commitOperations(ctx: StateContext<TagManagerStateModel>) {
    const selectedTag = ctx.getState().selectedTag;
    if (!selectedTag) return;

    ctx.setState(
      patch<TagManagerStateModel>({
        uiState: patch<Record<string, any>>({
          editStatus: "saving"
        })
      })
    );

    const operations = ctx.getState().operations;
    if (operations.length > 0)
      this.tagsApiService
        .patch(selectedTag.tagId, operations)
        .pipe(
          tap(() => {
            ctx.setState(
              patch<TagManagerStateModel>({
                uiState: patch<Record<string, any>>({
                  editStatus: "saved"
                })
              })
            );
          }),
          delay(1000)
        )
        .subscribe({
          next: () => {
            ctx.setState(
              patch<TagManagerStateModel>({
                operations: [],
                tags: updateItem<Tag>(tag => tag?.tagId === selectedTag.tagId, selectedTag),
                uiState: patch<Record<string, any>>({
                  editStatus: "untouched"
                })
              })
            );
          },
          error: (error: HttpErrorResponse) => {
            this.loggerService.errorEvent(
              error,
              `${this.constructor.name}.${this.commitOperations.name}`,
              "An Error occured when attempting to commit operations."
            );
          }
        });
  }

  @Action(TagManagerActions.PatchUiState)
  patchUiState(ctx: StateContext<TagManagerStateModel>, action: TagManagerActions.PatchUiState) {
    ctx.setState(
      patch<TagManagerStateModel>({
        uiState: patch<Record<string, any>>(action.uiState)
      })
    );
  }

  ngxsAfterBootstrap(): void {
    console.log("Tag Manager State Initialized");
  }
}

const updateState = (tagManagerState: Readonly<Partial<TagManagerStateModel>>) =>
  ({ ...tagManagerState } as Partial<TagManagerStateModel>);
