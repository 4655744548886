import { Override, PricingPlanOverride, ServiceCost, Tag } from "@vp/models";
import { Operation } from "rfc6902";
import { TagManagerStateModel } from "../models/tag-manager-state.model";

export class UpdateState {
  public static readonly type = "[Tag Manager] Update State";
  constructor(public tagManagerState: Partial<TagManagerStateModel>) {}
}

export class SelectTagType {
  public static readonly type = "[Tag Manager] Select Tag Type";
  constructor(public tagTypeId: string) {}
}

export class EditTagType {
  public static readonly type = "[Tag Manager] Activate Tag Type";
  constructor(public tagTypeId: string) {}
}

export class SelectTag {
  public static readonly type = "[Tag Manager] Select Tag";
  constructor(public tagId: string) {}
}

export class AddTag {
  public static readonly type = "[Tag Manager] Add Tag";
  constructor(public tag: Partial<Tag>) {}
}

export class DeleteTag {
  public static readonly type = "[Tag Manager] Delete Tag";
  constructor(public tagId: string) {}
}

export class UpdateTag {
  public static readonly type = "[Tag Manager] Edit Tag";
  constructor(public tagId: string, public tag: Partial<Tag>) {}
}

export class UnselectTagType {
  public static readonly type = "[Tag Manager] Unselect Tag Type";
  constructor(public tagTypeId: string) {}
}

export class UnselectTag {
  public static readonly type = "[Tag Manager] Unselect Tag";
  constructor(public tagId: string) {}
}

export class AddServiceCostPricingPlan {
  public static readonly type = "[Tag Manager] Add Service Cost Pricing Plan";
  constructor(public tagId: string, public planName: string) {}
}

// export class EditServiceCostPricingPlan {
//   public static readonly type = "[Tag Manager] Edit Service Cost Pricing Plan";
//   constructor(public tagId: string, public override: PricingPlanOverride) {}
// }

export class AddSpecialty {
  public static readonly type = "[Tag Manager] Add Specialty";
  constructor(public tagId: string, public planName: string, public specialtyTagId: string) {}
}

export class AddRecipient {
  public static readonly type = "[Tag Manager] Add Recipient";
  constructor(public tagId: string, public recipientAdded: string) {}
}

export class DeleteRecipient {
  public static readonly type = "[Tag Manager] Delete Recipient";
  constructor(public tagId: string, public recipientRemoved: string) {}
}

export class DeleteSpecialty {
  public static readonly type = "[Tag Manager] Delete Specialty";
  constructor(public tagId: string, public planName: string, public specialtyTagId: string) {}
}

export class DeletePlan {
  public static readonly type = "[Tag Manager] Delete Plan";
  constructor(public tagId: string, public planName: string) {}
}

export class DeleteCostOverride {
  public static readonly type = "[Tag Manager] Delete Cost Override";
  constructor(public tagId: string, public caseTypeId: string) {}
}

export class AddServiceCost {
  public static readonly type = "[Tag Manager] Add Service Cost";
  constructor(public tagId: string, public planName: string, public serviceFee: ServiceCost) {}
}

export class EditServiceCost {
  public static readonly type = "[Tag Manager] Edit Service Cost";
  constructor(public tagId: string, public planName: string, public serviceFee: ServiceCost) {}
}

export class DeleteServiceCost {
  public static readonly type = "[Tag Manager] Delete Service Cost";
  constructor(
    public tagId: string,
    public planName: string,
    public serviceCostFriendlyId: string
  ) {}
}

export class PatchPricingPlanOverride {
  public static readonly type = "[Tag Manager] Patch Pricing Plan Override";
  constructor(
    public tagId: string,
    public planName: string,
    public overridePartial: Partial<PricingPlanOverride>
  ) {}
}

export class PatchCostOverride {
  public static readonly type = "[Tag Manager] Patch Cost Override";
  constructor(public tagId: string, public override: Override[]) {}
}

export class MergeOperations {
  public static readonly type = "[Tag Manager] Add Operations";
  constructor(public operations: Operation[]) {}
}

export class ResetOperations {
  public static readonly type = "[Tag Manager] Reset Operations";
}

export class CommitOperations {
  public static readonly type = "[Tag Manager] Commit Operations";
}

export class PatchUiState {
  public static readonly type = "[Tag Manager] Patch Ui State";
  constructor(public uiState: { [key: string]: string | boolean }) {}
}

export class CancelEditingTag {
  public static readonly type = "[Tag Manager] Cancel Editing Tag";
}
