<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
  isPaid: (isPaid$ | async),
  fee: (fee$ | async),
  disabled: (disabled$ | async),
  cybersourceAdditionalMsg: (cybersourceAdditionalMsg$ | async),
  isLoading: (isLoading$ | async)
}"
>
</ng-container>

<ng-template
  #template
  let-isPaid="isPaid"
  let-fee="fee"
  let-disabled="disabled"
  let-cybersourceAdditionalMsg="cybersourceAdditionalMsg"
  let-isLoading="isLoading"
  >
  <div *ngIf="isPaid === false">
  <p>
    A fee of <b>{{ fee | currency }}</b> is payable. {{cybersourceAdditionalMsg}}
  </p>
  <div flexLayout="row" flexLayoutAlign="center" *ngIf="isLoading">
    <mat-spinner diameter="40"></mat-spinner>
  </div>

  <form
    (ngSubmit)="onSubmit(form)"
    #form="ngForm"
    flexLayout="column"
    flexLayoutGap="10px"
    flexLayoutAlign="space-around"
    [ngClass]="{ isLoading: isLoading === true }"
  >
    <div>
      <label id="cardNumber-label">Card Number</label>
      <div id="number-container" class="form-control"></div>
    </div>
    <div>
      <label for="securityCode-container">Security Code</label>
      <div id="securityCode-container" class="form-control"></div>
    </div>

    <div>
      <label for="expMonth">Expiry Month</label>
      <select
      [disabled]="disabled"
      id="expMonth"
      [ngClass]="{
        'form-control-disabled': disabled === true,
        'form-control': disabled === false,
      }"
      name="expMonth" ngModel>
        <option>01</option>
        <option>02</option>
        <option>03</option>
        <option>04</option>
        <option>05</option>
        <option>06</option>
        <option>07</option>
        <option>08</option>
        <option>09</option>
        <option>10</option>
        <option>11</option>
        <option>12</option>
      </select>
    </div>
    <div>
      <label for="expYear">Expiry Year</label>
      <select
      [disabled]="disabled"
      id="expYear"
      [ngClass]="{
        'form-control-disabled': disabled === true,
        'form-control': disabled === false
      }"
      name="expYear" ngModel>
        <option *ngFor="let year of yearList">{{ year }}</option>
      </select>
    </div>
    <div flexLayout="row" flexLayoutAlign="end end">
      <button [disabled]="disabled" mat-raised-button color="primary">Submit Payment</button>
    </div>
  </form>
  </div>
  <div>
    <p *ngIf="isPaid">
      {{ fee ? 'Payment has been received, please proceed to submit your case.' : 'There is no charge for submitting this case.' }}
    </p>
  </div>
</ng-template>
